export const STORE_LIST_REQUEST = "STORE_LIST_REQUEST";
export const STORE_LIST_SUCCESS = "STORE_LIST_SUCCESS";
export const STORE_LIST_FAIL = "STORE_LIST_FAIL";

export const STORE_ADD_REQUEST = "STORE_ADD_REQUEST";
export const STORE_ADD_SUCCESS = "STORE_ADD_SUCCESS";
export const STORE_ADD_FAIL = "STORE_ADD_FAIL";

export const STORE_DETAIL_REQUEST = "STORE_DETAIL_REQUEST";
export const STORE_DETAIL_SUCCESS = "STORE_DETAIL_SUCCESS";
export const STORE_DETAIL_FAIL = "STORE_DETAIL_FAIL";

export const STORE_UPDATE_REQUEST = "STORE_UPDATE_REQUEST";
export const STORE_UPDATE_SUCCESS = "STORE_UPDATE_SUCCESS";
export const STORE_UPDATE_FAIL = "STORE_UPDATE_FAIL";

export const STORE_DELETE_REQUEST = "STORE_DELETE_REQUEST";
export const STORE_DELETE_SUCCESS = "STORE_DELETE_SUCCESS";
export const STORE_DELETE_FAIL = "STORE_DELETE_FAIL";

export const STORE_EMPLOYE_LIST_REQUEST = "STORE_EMPLOYE_LIST_REQUEST";
export const STORE_EMPLOYE_LIST_SUCCESS = "STORE_EMPLOYE_LIST_SUCCESS";
export const STORE_EMPLOYE_LIST_FAIL = "STORE_EMPLOYE_LIST_FAIL";

export const STORE_EMPLOYE_DELETE_REQUEST = "STORE_EMPLOYE_DELETE_REQUEST";
export const STORE_EMPLOYE_DELETE_SUCCESS = "STORE_EMPLOYE_DELETE_SUCCESS";
export const STORE_EMPLOYE_DELETE_FAIL = "STORE_EMPLOYE_DELETE_FAIL";

export const STORE_EMPLOYE_DISPONIBLE_LIST_REQUEST =
  "STORE_EMPLOYE_DISPONIBLE_LIST_REQUEST";
export const STORE_EMPLOYE_DISPONIBLE_LIST_SUCCESS =
  "STORE_EMPLOYE_DISPONIBLE_LIST_SUCCESS";
export const STORE_EMPLOYE_DISPONIBLE_LIST_FAIL =
  "STORE_EMPLOYE_DISPONIBLE_LIST_FAIL";

export const STORE_EMPLOYE_ADD_REQUEST = "STORE_EMPLOYE_ADD_REQUEST";
export const STORE_EMPLOYE_ADD_SUCCESS = "STORE_EMPLOYE_ADD_SUCCESS";
export const STORE_EMPLOYE_ADD_FAIL = "STORE_EMPLOYE_ADD_FAIL";
