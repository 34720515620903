import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  confirmOrder,
  deleteOrder,
  getListOrdersClient,
} from "../../redux/actions/orderActions";
import ConfirmationModal from "../../components/ConfirmationModal";
import Paginate from "../../components/Paginate";
import { toast } from "react-toastify";
import InputModel from "../../components/InputModel";
import { deleteReservation } from "../../redux/actions/reservationActions";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";
import DefaultLayout from "../../layouts/DefaultLayout";

function HistoryOrderScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  let { id } = useParams();

  const page = searchParams.get("page") || "1";

  const [orderId, setOrderId] = useState("");
  const [eventType, setEventType] = useState("");
  const [loadEvent, setLoadEvent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const [confirmImagev, setConfirmImagev] = useState("");
  const [confirmImage, setConfirmImage] = useState("");
  const [confirmImageError, setConfirmImageError] = useState("");

  const [confirmNote, setConfirmNote] = useState("");
  const [confirmNoteError, setConfirmNoteError] = useState("");

  //
  const orderConfirm = useSelector((state) => state.confirmOrder);
  const { loadingOrderConfirm, errorOrderConfirm, successOrderConfirm } =
    orderConfirm;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listOrdersClient = useSelector((state) => state.orderListClient);
  const {
    orders,
    loadingOrdersClient,
    errorOrdersClient,
    successOrdersClient,
    pages,
  } = listOrdersClient;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListOrdersClient(page, id));
    }
  }, [navigate, userInfo, dispatch, page]);

  const [orderCode, setOrderCode] = useState("");
  const [clientName, setClientName] = useState("");
  const [reservationDate, setReservationDate] = useState("");
  const [reservationStatus, setReservationStatus] = useState("");

  useEffect(() => {
    if (successOrderConfirm) {
      setLoadEvent(false);
      setEventType("");
      setOrderId("");
      setIsShowConfirm(false);
      setIsOpen(false);
      dispatch(getListOrdersClient(1, id));
    }
  }, [successOrderConfirm]);

  const orderDelete = useSelector((state) => state.deleteOrder);
  const { loadingOrderDelete, errorOrderDelete, successOrderDelete } =
    orderDelete;

  useEffect(() => {
    if (successOrderDelete) {
      dispatch(getListOrdersClient(1, id));
    }
  }, [successOrderDelete, dispatch]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Orders</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex flex-col justify-between">
            <h4 className=" uppercase font-semibold text-black ">
              ORDERS MANAGEMENT
            </h4>
          </div>

          {/* list */}
          {loadingOrdersClient ? (
            <Loader />
          ) : errorOrdersClient ? (
            <Alert type="error" message={errorOrdersClient} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left ">
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black text-xs w-max ">
                      N°
                    </th>
                    <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                      Order Number
                    </th>
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black text-xs w-max">
                      Items
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Date
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Client
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Email
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Phone
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Total Amount
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Statut
                    </th>
                    <th className="py-4 px-4 font-bold text-black text-xs w-max">
                      Operations
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {orders?.map((order, index) => (
                    <tr key={index}>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max   flex flex-row  justify-end ">
                          <div>{order.id}</div>
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.order_number ?? ""}
                        </p>
                      </td>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max   flex flex-row  justify-end ">
                          <div>{order.items}</div>
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.date ?? ""}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.client_name ?? "---"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.client_email ?? "---"}
                        </p>
                      </td>

                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.client_phone ?? "---"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {parseFloat(order.total_amount).toFixed(2) ?? "0.00"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {order.status ?? "---"}
                        </p>
                      </td>

                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max flex flex-row  ">
                          <Link
                            className="mx-1 detail-class"
                            to={"/orders/detail/" + order.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-warning rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>
                          </Link>

                          {userInfo &&
                            order.status == "PROCESSING" &&
                            [1, 2, 4].includes(userInfo.role) && (
                              <div
                                onClick={() => {
                                  setEventType("confirm-preparing");
                                  setLoadEvent(false);
                                  setOrderId(order.id);
                                  setIsShowConfirm(true);
                                }}
                                className="mx-1 confirm-class cursor-pointer"
                              >
                                {/* confirm */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5 bg-green rounded p-1 text-white text-center text-xs"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m4.5 12.75 6 6 9-13.5"
                                  />
                                </svg>
                              </div>
                            )}

                          {/* delete */}
                          {userInfo &&
                            ((order.status == "PROCESSING" &&
                              [1, 2, 3].includes(userInfo.role)) ||
                              [1, 2].includes(userInfo.role)) && (
                              <div
                                onClick={() => {
                                  setEventType("delete");
                                  setLoadEvent(false);
                                  setOrderId(order.id);
                                  setIsOpen(true);
                                }}
                                className="mx-1 delete-class cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                  />
                                </svg>
                              </div>
                            )}
                          {/* edit */}
                          {userInfo && [1, 2, 3].includes(userInfo.role) && (
                            <Link
                              className="mx-1 update-class"
                              to={"/orders/edit/" + order.id}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                              </svg>
                            </Link>
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
            </div>
          )}
          <div className="">
            <Paginate
              route={`/clients/history/${id}?`}
              search={""}
              page={page}
              pages={pages}
            />
          </div>
        </div>
        {/* buttom dash */}
        {isShowConfirm && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-99999 bg-black bg-opacity-20">
            <div className="bg-white shadow-lg rounded mx-3 px-8 pt-6 pb-8 mb-4 md:w-1/3 w-screen">
              <h3 className="text-lg font-bold mb-4">Confirmation</h3>

              <div className="my-3">
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Image"
                    type="file"
                    placeholder=""
                    value={confirmImagev}
                    onChange={(v) => {
                      setConfirmImage(v.target.files[0]);
                      setConfirmImagev(v.target.value);
                    }}
                    error={confirmImageError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Note"
                    type="textarea"
                    placeholder=""
                    value={confirmNote}
                    onChange={(v) => {
                      setConfirmNote(v.target.value);
                    }}
                    error={confirmNoteError}
                  />
                </div>
              </div>
              {/*  */}
              <div className="my-2 flex flex-row items-center justify-end">
                <button
                  onClick={() => {
                    setEventType("");
                    setIsOpen(false);
                    setIsShowConfirm(false);
                    setOrderId("");
                  }}
                  className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                >
                  Cancel
                </button>
                <button
                  onClick={async () => {
                    var check = true;

                    if (check) {
                      setEventType("confirm-preparing");
                      setIsOpen(true);
                    } else {
                      toast.error("Some fields are mandatory please check");
                    }
                  }}
                  className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 mx-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        <ConfirmationModal
          isOpen={isOpen}
          message={
            eventType === "delete"
              ? "Are you sure you want delete this order?"
              : eventType === "confirm-preparing"
              ? "Are you sure you want to confirm this order for preparation ?"
              : "Are you sure you want to confirm this operation ?"
          }
          onConfirm={async () => {
            if (eventType === "delete" && orderId !== "") {
              setLoadEvent(true);
              await dispatch(deleteOrder(orderId)).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsOpen(false);
            } else if (eventType === "confirm-preparing" && orderId !== "") {
              setLoadEvent(true);
              await dispatch(
                confirmOrder(orderId, {
                  confirm_image: confirmImage,
                  confirm_note: confirmNote,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsShowConfirm(false);
              setIsOpen(false);
            } else {
              setLoadEvent(false);
              setEventType("");
              setIsOpen(false);
            }
          }}
          onCancel={() => {
            setIsOpen(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default HistoryOrderScreen;
