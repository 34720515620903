import { toast } from "react-toastify";
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  //
  PRODUCT_ADD_REQUEST,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAIL,
  //
  PRODUCT_DETAIL_REQUEST,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_DETAIL_FAIL,
  //
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  //
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  //
} from "../constants/productConstants";

export const detailProductReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_DETAIL_REQUEST:
      return { loadingProductDetail: true };
    case PRODUCT_DETAIL_SUCCESS:
      return {
        loadingProductDetail: false,
        successProductDetail: true,
        product: action.payload.product,
      };
    case PRODUCT_DETAIL_FAIL:
      return {
        loadingProductDetail: false,
        successProductDetail: false,
        errorProductDetail: action.payload,
      };
    default:
      return state;
  }
};

export const createNewProductReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_ADD_REQUEST:
      return { loadingProductAdd: true };
    case PRODUCT_ADD_SUCCESS:
      toast.success("This Product has been successfully added");
      return {
        loadingProductAdd: false,
        successProductAdd: true,
      };
    case PRODUCT_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingProductAdd: false,
        successProductAdd: false,
        errorProductAdd: action.payload,
      };
    default:
      return state;
  }
};

export const deleteProductReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loadingProductDelete: true };
    case PRODUCT_DELETE_SUCCESS:
      toast.success("this product has been deleted");
      return {
        loadingProductDelete: false,
        successProductDelete: true,
      };
    case PRODUCT_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingProductDelete: false,
        successProductDelete: false,
        errorProductDelete: action.payload,
      };
    default:
      return state;
  }
};

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loadingProductList: true, products: [] };
    case PRODUCT_LIST_SUCCESS:
      return {
        loadingProductList: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PRODUCT_LIST_FAIL:
      return { loadingProductList: false, errorProductList: action.payload };
    default:
      return state;
  }
};
