import React from "react";
import Select from "react-select";

const InputModel = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  error,
  options,
  isPrice,
  min,
  max,
  disabled = false,
  refr = null,
  accept = null,
  ismultiple = false,
  zindex = "z-99",
}) => {
  const inputElement =
    type === "searchselect" ? (
      <Select
        className={`font-bold text-[14px] block w-full  h-[34px] text-[#555] ${zindex}  rounded `}
        value={value}
        onChange={onChange}
        options={options ?? []}
        isSearchable
        placeholder=""

        // noOptionsMessage={""}
      />
    ) : type === "select" ? (
      <select
        ref={refr}
        disabled={disabled}
        multiple={ismultiple}
        className={`${
          disabled ? "bg-gray" : ""
        } font-bold text-[14px] block w-full p-2 h-[34px] py-[6px] px-3 text-[#555] border-[#ccc] focus:border rounded border`}
        value={value}
        style={{
          boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
          transition:
            "border-color ease-in-out .15s, box-shadow ease-in-out .15s",
        }}
        onChange={onChange}
      >
        <option value={""}></option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : type === "textarea" ? (
      <textarea
        disabled={disabled}
        ref={refr}
        placeholder={placeholder}
        style={{
          boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
          transition:
            "border-color ease-in-out .15s, box-shadow ease-in-out .15s",
          resize: "vertical",
        }}
        className={`${
          disabled ? "bg-gray" : ""
        } font-bold text-[14px] block w-full p-2 py-[6px] px-3 text-[#555] border-[#ccc] focus:border rounded border`}
        value={value}
        onChange={onChange}
      />
    ) : type === "date" || type === "time" ? (
      <input
        disabled={disabled}
        type={type}
        ref={refr}
        min={min}
        max={max}
        accept={accept}
        step={isPrice ? 0.01 : 1}
        placeholder={placeholder}
        style={{
          boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
          transition:
            "border-color ease-in-out .15s, box-shadow ease-in-out .15s",
        }}
        className={`${
          disabled ? "bg-gray" : ""
        }  font-bold text-[14px] block w-full p-2 h-[34px] py-[6px] px-3 text-[#555] border-[#ccc] focus:border rounded border`}
        value={value}
        onChange={onChange}
      />
    ) : type === "file" ? (
      <input
        disabled={disabled}
        type={type}
        ref={refr}
        accept={accept}
        placeholder={placeholder}
        value={value}
        style={{
          boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
          transition:
            "border-color ease-in-out .15s, box-shadow ease-in-out .15s",
        }}
        className={`${
          disabled ? "bg-gray" : ""
        }  font-bold text-[14px] block w-full p-2 h-[34px] py-[6px] px-3 text-[#555] border-[#ccc] focus:border rounded border`}
        onChange={onChange}
      />
    ) : (
      <input
        disabled={disabled}
        type={type}
        ref={refr}
        min={0}
        accept={accept}
        step={isPrice ? 0.01 : 1}
        placeholder={placeholder}
        style={{
          boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
          transition:
            "border-color ease-in-out .15s, box-shadow ease-in-out .15s",
        }}
        className={`${
          disabled ? "bg-gray" : ""
        }  font-bold text-[14px] block w-full p-2 h-[34px] py-[6px] px-3 text-[#555] border-[#ccc] focus:border rounded border`}
        value={value}
        onChange={onChange}
      />
    );

  return (
    <div className="md:flex-1 md:mr-1 md:mb-0 mb-5">
      <div className={`mt-1 relative`}>
        <label
          className={`absolute text-[#898989] text-[14px] mt-[-16px] ml-[7px] px-[5px] bg-[#FFFFFF] line-clamp-1 ${
            type === "searchselect"
              ? zindex === "z-999"
                ? "z-999"
                : "z-99"
              : ""
          }`}
        >
          {label}
        </label>
        {inputElement}
      </div>
      {error && (
        <p className="text-[9px] italic text-danger leading-none mt-1">
          {error}
        </p>
      )}
    </div>
  );
};

export default InputModel;
