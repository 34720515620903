import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  confirmOrder,
  confirmShippedOrder,
  detailOrder,
  getItemsOrder,
} from "../../redux/actions/orderActions";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import LayoutSection from "../../components/LayoutSection";
import { baseURLFile } from "../../constants";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import InputModel from "../../components/InputModel";

function DetailOrderScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [eventType, setEventType] = useState("");
  const [loadEvent, setLoadEvent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const [confirmImagev, setConfirmImagev] = useState("");
  const [confirmImage, setConfirmImage] = useState("");
  const [confirmImageError, setConfirmImageError] = useState("");

  const [confirmNote, setConfirmNote] = useState("");
  const [confirmNoteError, setConfirmNoteError] = useState("");

  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingNumberError, setTrackingNumberError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const orderDetail = useSelector((state) => state.detailOrder);
  const { successOrderDetail, loadingOrderDetail, order, errorOrderDetail } =
    orderDetail;

  const orderItem = useSelector((state) => state.itemsOrder);
  const { successOrderItems, loadingOrderItems, orderItems, errorOrderItems } =
    orderItem;

  const orderConfirm = useSelector((state) => state.confirmOrder);
  const { loadingOrderConfirm, errorOrderConfirm, successOrderConfirm } =
    orderConfirm;

  const orderConfirmShipped = useSelector((state) => state.confirmShippedOrder);
  const { loadingOrderShipped, errorOrderShipped, successOrderShipped } =
    orderConfirmShipped;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailOrder(id));
      dispatch(getItemsOrder(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (successOrderConfirm) {
      setLoadEvent(false);
      setEventType("");
      setIsShowConfirm(false);
      setIsOpen(false);
      dispatch(detailOrder(id));
      dispatch(getItemsOrder(id));
    }
  }, [successOrderConfirm]);

  useEffect(() => {
    if (successOrderShipped) {
      setLoadEvent(false);
      setEventType("");
      setIsShowConfirm(false);
      setIsOpen(false);
      dispatch(detailOrder(id));
      dispatch(getItemsOrder(id));
    }
  }, [successOrderShipped]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/orders/">
            <div className="">Orders</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Detail</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Order Detail N°:{id}
            </h4>
          </div>
          {/*  */}
          {loadingOrderDetail ? (
            <Loader />
          ) : errorOrderDetail ? (
            <Alert type="error" message={errorOrderDetail} />
          ) : order ? (
            <div>
              {/* info  */}
              <div>
                <LayoutSection
                  title="Order Info"
                  styles="bg-primary text-white font-bold mb-2"
                >
                  <div className="my-3 mx-2 flex md:flex-row flex-col-reverse ">
                    <div className="md:w-1/2 w-full my-1">
                      <div className="font-bold">Date: {order.date}</div>
                      <br />
                      <div className="font-bold">Customer:</div>
                      <div>{order.client_name}</div>
                      <div>{order.client_email}</div>
                      <div>{order.client_phone}</div>
                      <br />
                      <div className="font-bold">Shipping Address:</div>
                      <div>{order.shipping_address?.address}</div>
                      <br />
                      <div className="font-bold">Note:</div>
                      <div>{order.note}</div>
                    </div>
                    <div className="md:w-1/2 w-full my-1">
                      <div className="font-bold">
                        Total: {parseFloat(order.total_amount).toFixed(2)}
                      </div>
                      <br />
                      <div className="font-bold">Status: {order.status}</div>
                      <br />
                      <div className="font-bold">
                        Order Number: {order.order_number}
                      </div>
                      <div className="font-bold">
                        Created At: {order.created_at}
                      </div>
                      <br />
                      <div className="font-bold">
                        Tracking Number: {order.tracking_number}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex md:flex-row flex-col">
                    {[1, 2, 3].includes(userInfo.role) ? (
                      <div className="md:w-1/2 w-full flex flex-row">
                        <a
                          href={`/orders/edit/${id}`}
                          className="w-1/2  my-1 mx-1 bg-primary text-white font-bold px-3 py-2 rounded text-center text-sm"
                        >
                          Update
                        </a>
                        <div
                          onClick={() => {}}
                          className="w-1/2  my-1 mx-1 bg-danger text-white font-bold px-3 py-2 rounded  text-center  cursor-pointer text-sm"
                        >
                          Cancel
                        </div>
                      </div>
                    ) : null}

                    <div className="md:w-1/2 w-full flex flex-row">
                      {order.status === "PREPARING" &&
                      [1, 2, 3, 5].includes(userInfo.role) ? (
                        <div
                          onClick={() => {
                            setEventType("confirm-shipping");
                            setLoadEvent(false);
                            setIsShowConfirm(true);
                          }}
                          className="w-1/2  my-1 mx-1 bg-green text-white font-bold px-3 py-2 rounded  text-center cursor-pointer text-sm"
                        >
                          Confirm Shipping
                        </div>
                      ) : null}
                      {userInfo &&
                      order.status == "PROCESSING" &&
                      [1, 2, 3, 6].includes(userInfo.role) ? (
                        <div
                          onClick={() => {
                            setEventType("confirm-preparing");
                            setConfirmImage("");
                            setConfirmImageError("");
                            setConfirmImagev("");
                            setConfirmNote("");
                            setConfirmNoteError("");
                            setLoadEvent(false);
                            setIsShowConfirm(true);
                          }}
                          className="w-1/2  my-1 mx-1 bg-green text-white font-bold px-3 py-2 rounded  text-center cursor-pointer text-sm"
                        >
                          Confirm Preparing
                        </div>
                      ) : null}
                      <div className="w-1/2  my-1 mx-1"></div>
                    </div>
                  </div>
                </LayoutSection>

                <LayoutSection
                  title={`Order Items (${order.items})`}
                  styles="bg-danger text-white font-bold mb-2"
                >
                  <div>
                    <div className="max-w-full overflow-x-auto mt-1 mb-1">
                      <table className="w-full table-auto">
                        <thead>
                          <tr className="bg-gray-2 text-left ">
                            <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                              N°
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                              Image
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                              Product Name
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                              Product Price
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                              Quantity
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                              Variation
                            </th>
                          </tr>
                        </thead>
                        {/*  */}
                        <tbody>
                          {loadingOrderItems ? (
                            <></>
                          ) : errorOrderItems ? (
                            <></>
                          ) : (
                            orderItems?.map((item, index) => (
                              <tr key={index}>
                                <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                                  <p className="text-black  text-xs w-max   flex flex-row  justify-end ">
                                    <div>{item.id}</div>
                                  </p>
                                </td>
                                <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                                  <p className="text-black  text-xs w-max  ">
                                    <a href={baseURLFile + item.product_image}>
                                      <img
                                        src={baseURLFile + item.product_image}
                                        className="w-10 h-10 "
                                        alt={item.product_name}
                                      />
                                    </a>
                                  </p>
                                </td>
                                <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                                  <p className="text-black  text-xs w-max  ">
                                    {item.product_name}
                                  </p>
                                </td>
                                <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                                  <p className="text-black  text-xs w-max  ">
                                    {parseFloat(item.product_price).toFixed(2)}
                                  </p>
                                </td>
                                <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                                  <p className="text-black  text-xs w-max  ">
                                    {item.quantity}
                                  </p>
                                </td>
                                <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                                  <p className="text-black  text-xs w-max  ">
                                    {item.variation ?? "----"}
                                  </p>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </LayoutSection>
              </div>
            </div>
          ) : null}
        </div>
        {isShowConfirm && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-99999 bg-black bg-opacity-20">
            <div className="bg-white shadow-lg rounded mx-3 px-8 pt-6 pb-8 mb-4 md:w-1/3 w-screen">
              <h3 className="text-lg font-bold mb-4">Confirmation</h3>

              <div className="my-3">
                {eventType === "confirm-preparing" ? (
                  <div className="md:py-2 md:flex ">
                    <InputModel
                      label="Image"
                      type="file"
                      placeholder=""
                      value={confirmImagev}
                      onChange={(v) => {
                        setConfirmImage(v.target.files[0]);
                        setConfirmImagev(v.target.value);
                      }}
                      error={confirmImageError}
                    />
                  </div>
                ) : null}
                {eventType === "confirm-preparing" ? (
                  <div className="md:py-2 md:flex ">
                    <InputModel
                      label="Note"
                      type="textarea"
                      placeholder=""
                      value={confirmNote}
                      onChange={(v) => {
                        setConfirmNote(v.target.value);
                      }}
                      error={confirmNoteError}
                    />
                  </div>
                ) : null}
                {eventType === "confirm-shipping" ? (
                  <div className="md:py-2 md:flex ">
                    <InputModel
                      label="Tracking Number"
                      type="text"
                      placeholder=""
                      value={trackingNumber}
                      onChange={(v) => {
                        setTrackingNumber(v.target.value);
                      }}
                      error={trackingNumberError}
                    />
                  </div>
                ) : null}
              </div>
              {/*  */}
              <div className="my-2 flex flex-row items-center justify-end">
                <button
                  onClick={() => {
                    setEventType("");
                    setIsOpen(false);
                    setIsShowConfirm(false);
                  }}
                  className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                >
                  Cancel
                </button>
                <button
                  onClick={async () => {
                    var check = true;

                    if (
                      eventType === "confirm-shipping" &&
                      trackingNumber === ""
                    ) {
                      setTrackingNumberError("This field is required.");
                      check = false;
                    }

                    if (check) {
                      setIsOpen(true);
                    } else {
                      toast.error("Some fields are mandatory please check");
                    }
                  }}
                  className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 mx-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        <ConfirmationModal
          isOpen={isOpen}
          message={
            eventType === "cancel"
              ? "Are you sure you want to Cancel this Order ?"
              : eventType === "confirm-preparing"
              ? "Are you sure you want to confirm this order for preparation ?"
              : eventType === "confirm-shipping"
              ? "Are you sure you want to confirm this order for shipping ?"
              : "Êtes-vous sûr de vouloir confirmer cette operation ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setLoadEvent(true);
              // await dispatch(deleteReservation(id)).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsOpen(false);
            } else if (eventType === "confirm-preparing") {
              setLoadEvent(true);

              await dispatch(
                confirmOrder(id, {
                  confirm_image: confirmImage,
                  confirm_note: confirmNote,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsShowConfirm(false);
              setIsOpen(false);
            } else if (eventType === "confirm-shipping") {
              setLoadEvent(true);
              await dispatch(
                confirmShippedOrder(id, {
                  tracking_number: trackingNumber,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsShowConfirm(false);
              setIsOpen(false);
            } else {
              setLoadEvent(false);
              setEventType("");
              setIsOpen(false);
            }
          }}
          onCancel={() => {
            setIsOpen(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default DetailOrderScreen;
