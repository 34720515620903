import "./App.css";
import "./axios.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginScreen from "./screens/auth/LoginScreen";
import LogoutScreen from "./screens/auth/LogoutScreen.js";
import DashboardScreen from "./screens/dashboard/DashboardScreen.js";
import ClientScreen from "./screens/client/ClientScreen.js";
import AddClientScreen from "./screens/client/AddClientScreen.js";
import EditClientScreen from "./screens/client/EditClientScreen.js";

import UserScreen from "./screens/users/UserScreen.js";
import AddUserScreen from "./screens/users/AddUserScreen.js";
import EditUserScreen from "./screens/users/EditUserScreen.js";

import ReservationScreen from "./screens/reservation/ReservationScreen.js";
import AddReservationScreen from "./screens/reservation/AddReservationScreen.js";
import EditReservationScreen from "./screens/reservation/EditReservationScreen.js";

import ProfileScreen from "./screens/profile/ProfileScreen.js";
import OrderScreen from "./screens/orders/OrderScreen.js";
import AddOrderScreen from "./screens/orders/AddOrderScreen.js";
import DetailOrderScreen from "./screens/orders/DetailOrderScreen.js";
import EditOrderScreen from "./screens/orders/EditOrderScreen.js";
import HistoryOrderScreen from "./screens/orders/HistoryOrderScreen.js";
import StoreScreen from "./screens/stores/StoreScreen.js";
import EditStoreScreen from "./screens/stores/EditStoreScreen.js";
import ProductScreen from "./screens/product/ProductScreen.js";
import AddProductScreen from "./screens/product/AddProductScreen.js";
import EditProductScreen from "./screens/product/EditProductScreen.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginScreen />,
  },
  {
    path: "/dashboard",
    element: <DashboardScreen />,
  },
  // client
  {
    path: "/clients",
    element: <ClientScreen />,
  },
  {
    path: "/clients/add",
    element: <AddClientScreen />,
  },
  {
    path: "/clients/edit/:id",
    element: <EditClientScreen />,
  },
  {
    path: "/clients/history/:id",
    element: <HistoryOrderScreen />,
  },

  // reservation
  {
    path: "/reservations",
    element: <ReservationScreen />,
  },
  {
    path: "/reservations/add",
    element: <AddReservationScreen />,
  },
  {
    path: "/reservations/edit/:id",
    element: <EditReservationScreen />,
  },
  // orders
  {
    path: "/orders",
    element: <OrderScreen />,
  },
  {
    path: "/orders/add",
    element: <AddOrderScreen />,
  },
  {
    path: "/orders/detail/:id",
    element: <DetailOrderScreen />,
  },
  {
    path: "/orders/edit/:id",
    element: <EditOrderScreen />,
  },

  // employes
  {
    path: "/users",
    element: <UserScreen />,
  },
  {
    path: "/users/add",
    element: <AddUserScreen />,
  },
  {
    path: "/users/edit/:id",
    element: <EditUserScreen />,
  },
  // stores
  {
    path: "/stores",
    element: <StoreScreen />,
  },
  {
    path: "/stores/edit/:id",
    element: <EditStoreScreen />,
  },
  // products
  {
    path: "/products",
    element: <ProductScreen />,
  },
  {
    path: "/products/add",
    element: <AddProductScreen />,
  },
  {
    path: "/products/edit/:id",
    element: <EditProductScreen />,
  },
  //
  {
    path: "/profile",
    element: <ProfileScreen />,
  },
  {
    path: "/logout",
    element: <LogoutScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
